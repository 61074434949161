import React from 'react';
import cx from 'classnames';

import styles from './Note.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  solid?: boolean;
  info?: boolean;
  warning?: boolean;
  error?: boolean;
  success?: boolean;
  small?: boolean;
}

export const Note: React.FC<Props> = ({
  children,
  className,
  solid,
  info,
  warning,
  error,
  success,
  small,
}) => (
  <span
    role='note'
    className={cx(
      styles.note,
      solid && styles.solid,
      info && styles.info,
      warning && styles.warning,
      error && styles.error,
      success && styles.success,
      small && styles.small,
      className,
    )}
  >
    {children}
  </span>
);

Note.displayName = 'Note';
export default Note;
export { styles };
